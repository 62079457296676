<template>
  <div class="modal-card">
    <ValidationObserver ref="form">
      <form @submit.prevent="testAnswer">
        <!-- For Header -->
        <header class="modal-card-head">
          <p class="modal-card-title">PREVIEW</p>
        </header>

        <!-- For Body -->
        <section
          class="modal-card-body wrapper"
          style="max-height: 500px; overflow: scroll;"
        >
          <template v-if="data.isUseExample">
            <h1 class="title">Example Question</h1>

            <span
              v-for="(question, indexQuestion) in exampleQuestion"
              :key="'example-' + indexQuestion"
            >
              <div class="box question" style="margin-top: 20px">
                <div class="columns is-multiline is-gapless">
                  <!-- For question -->
                  <div class="column is-12">
                    <b>Pertanyaan #{{ question.index }}</b>
                    <span v-if="question.isRequired == true" class="required">
                      *
                    </span>
                  </div>
                  <div class="column is-12">
                    <!-- For question text -->
                    <div v-html="question.question"></div>
                    <br />

                    <!-- For question file -->
                    <div v-if="question.files.length > 0">
                      <div
                        v-for="(file, indexFile) in question.files"
                        :key="indexFile"
                      >
                        <div v-if="file.fileType == 'image'">
                          <img v-lazy="getUrlFile(file.filePath)" alt="Image" />
                        </div>
                        <div v-else>
                          <template v-if="file.maxPlayVideo > 0">
                            <template
                              v-if="file.maxPlayVideo > file.totalPlayVideo"
                            >
                              <video-player
                                class="video-player-box"
                                width="320"
                                height="240"
                                controls="false"
                                muted="false"
                                autoplay
                                :ref="
                                  'video_' +
                                    question.id +
                                    '_' +
                                    question.index +
                                    '_' +
                                    indexFile
                                "
                                @ended="
                                  endVideo(
                                    question.id,
                                    question.index,
                                    indexFile,
                                    'example'
                                  )
                                "
                                :options="setOptions(getUrlFile(file.filePath))"
                              ></video-player>
                              <b-button
                                type="is-hcc"
                                @click="
                                  playVideo(
                                    question.id,
                                    question.index,
                                    indexFile
                                  )
                                "
                                style="margin: 15px 0px"
                              >
                                Play
                              </b-button>
                            </template>
                          </template>
                          <template v-else>
                            <video-player
                              width="320"
                              height="240"
                              controls="false"
                              muted="false"
                              autoplay
                              :ref="
                                'video_' +
                                  question.id +
                                  '_' +
                                  question.index +
                                  '_' +
                                  indexFile
                              "
                              @ended="
                                endVideo(
                                  question.id,
                                  question.index,
                                  indexFile,
                                  'example'
                                )
                              "
                              :options="setOptions(getUrlFile(file.filePath))"
                            ></video-player>
                            <b-button
                              type="is-hcc"
                              @click="
                                playVideo(
                                  question.id,
                                  question.index,
                                  indexFile
                                )
                              "
                              style="margin: 15px 0px"
                            >
                              Play
                            </b-button>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- For answer -->
                  <div class="column is-12">
                    <b>Jawaban</b>
                  </div>
                  <div class="column is-12">
                    <!-- For type essay -->
                    <template v-if="question.questionType == 'essay'">
                      <answer-type-essay
                        :data="question"
                        :indexQuestion="indexQuestion"
                      />
                    </template>

                    <!-- For type radio button -->
                    <template
                      v-else-if="question.questionType == 'radio button'"
                    >
                      <answer-type-radio-button
                        :data="question"
                        :indexQuestion="indexQuestion"
                      />
                    </template>

                    <!-- For type checkbox -->
                    <template v-else-if="question.questionType == 'checkbox'">
                      <answer-type-checkbox
                        :data="question"
                        :indexQuestion="indexQuestion"
                      />
                    </template>

                    <!-- For type linear scale -->
                    <template
                      v-else-if="question.questionType == 'linear scale'"
                    >
                      <answer-type-linear-scale
                        :data="question"
                        :indexQuestion="indexQuestion"
                      />
                    </template>

                    <!-- For type star rating -->
                    <template
                      v-else-if="question.questionType == 'star rating'"
                    >
                      <answer-type-star-rating
                        :data="question"
                        :indexQuestion="indexQuestion"
                      />
                    </template>

                    <!-- For type ranking -->
                    <template v-else-if="question.questionType == 'ranking'">
                      <answer-type-ranking
                        :data="question"
                        :indexQuestion="indexQuestion"
                      />
                    </template>

                    <!-- For type matrix -->
                    <template v-else-if="question.questionType == 'matrix'">
                      <answer-type-matrix
                        :data="question"
                        :indexQuestion="indexQuestion"
                        @clicked="autoSave($event)"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </span>
          </template>

          <br />

          <h1 class="title">Candidat Question</h1>
          <span
            v-for="(question, indexQuestion) in candidatQuestion"
            :key="'candidat-' + indexQuestion"
          >
            <div class="box question" style="margin-top: 20px">
              <div class="columns is-multiline is-gapless">
                <!-- For question -->
                <div class="column is-12">
                  <b>#{{ question.index }} Question</b>
                  <span v-if="question.isRequired == true" class="required">
                    *
                  </span>
                </div>
                <div class="column is-12">
                  <!-- For question text -->
                  <div v-html="question.question"></div>
                  <br />

                  <!-- For question file -->
                  <div v-if="question.files.length > 0">
                    <div
                      v-for="(file, indexFile) in question.files"
                      :key="indexFile"
                    >
                      <div v-if="file.fileType == 'image'">
                        <img v-lazy="getUrlFile(file.filePath)" alt="Image" />
                      </div>
                      <div v-else>
                        <template v-if="file.maxPlayVideo > 0">
                          <template
                            v-if="file.maxPlayVideo > file.totalPlayVideo"
                          >
                            <video-player
                              width="320"
                              height="240"
                              controls="false"
                              muted="false"
                              autoplay
                              :ref="
                                'video_' +
                                  question.id +
                                  '_' +
                                  question.index +
                                  '_' +
                                  indexFile
                              "
                              @ended="
                                endVideo(
                                  question.id,
                                  question.index,
                                  indexFile,
                                  'question'
                                )
                              "
                              :options="setOptions(getUrlFile(file.filePath))"
                            ></video-player>
                            <b-button
                              type="is-hcc"
                              @click="
                                playVideo(
                                  question.id,
                                  question.index,
                                  indexFile
                                )
                              "
                              style="margin: 15px 0px"
                            >
                              Play
                            </b-button>
                          </template>
                        </template>
                        <template v-else>
                          <video-player
                            class="video-player-box"
                            width="320"
                            height="240"
                            controls="false"
                            muted="false"
                            autoplay
                            :ref="
                              'video_' +
                                question.id +
                                '_' +
                                question.index +
                                '_' +
                                indexFile
                            "
                            @ended="
                              endVideo(
                                question.id,
                                question.index,
                                indexFile,
                                'question'
                              )
                            "
                            :options="setOptions(getUrlFile(file.filePath))"
                          ></video-player>
                          <b-button
                            type="is-hcc"
                            @click="
                              playVideo(question.id, question.index, indexFile)
                            "
                            style="margin: 15px 0px"
                          >
                            Play
                          </b-button>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- For answer -->
                <div class="column is-12">
                  <b>Jawaban</b>
                </div>
                <div class="column is-12">
                  <!-- For type essay -->
                  <template v-if="question.questionType == 'essay'">
                    <answer-type-essay
                      :data="question"
                      :indexQuestion="indexQuestion"
                    />
                  </template>

                  <!-- For type radio button -->
                  <template v-else-if="question.questionType == 'radio button'">
                    <answer-type-radio-button
                      :data="question"
                      :indexQuestion="indexQuestion"
                    />
                  </template>

                  <!-- For type checkbox -->
                  <template v-else-if="question.questionType == 'checkbox'">
                    <answer-type-checkbox
                      :data="question"
                      :indexQuestion="indexQuestion"
                    />
                  </template>

                  <!-- For type linear scale -->
                  <template v-else-if="question.questionType == 'linear scale'">
                    <answer-type-linear-scale
                      :data="question"
                      :indexQuestion="indexQuestion"
                    />
                  </template>

                  <!-- For type star rating -->
                  <template v-else-if="question.questionType == 'star rating'">
                    <answer-type-star-rating
                      :data="question"
                      :indexQuestion="indexQuestion"
                    />
                  </template>

                  <!-- For type ranking -->
                  <template v-else-if="question.questionType == 'ranking'">
                    <answer-type-ranking
                      :data="question"
                      :indexQuestion="indexQuestion"
                    />
                  </template>

                  <!-- For type matrix -->
                  <template v-else-if="question.questionType == 'matrix'">
                    <answer-type-matrix
                      :data="question"
                      :indexQuestion="indexQuestion"
                      @clicked="autoSave($event)"
                    />
                  </template>
                </div>
              </div>
            </div>
          </span>
        </section>

        <!-- For Footer -->
        <footer class="modal-card-foot">
          <button
            class="button is-danger"
            type="button"
            @click="$parent.close()"
          >
            Close
          </button>
          <button class="button is-hcc" native-type="submit">Save</button>
        </footer>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AnswerTypeCheckbox from '@/components/V2/Candidat/AnswerTypeCheckbox.vue'
import AnswerTypeEssay from '@/components/V2/Candidat/AnswerTypeEssay.vue'
import AnswerTypeLinearScale from '@/components/V2/Candidat/AnswerTypeLinearScale.vue'
import AnswerTypeRadioButton from '@/components/V2/Candidat/AnswerTypeRadioButton.vue'
import AnswerTypeRanking from '@/components/V2/Candidat/AnswerTypeRanking.vue'
import AnswerTypeStarRating from '@/components/V2/Candidat/AnswerTypeStarRating.vue'
import AnswerTypeMatrix from '@/components/V2/Candidat/AnswerTypeMatrix.vue'
import { videoPlayer } from 'vue-video-player'

export default {
  components: {
    videoPlayer,
    AnswerTypeEssay,
    AnswerTypeRadioButton,
    AnswerTypeCheckbox,
    AnswerTypeLinearScale,
    AnswerTypeStarRating,
    AnswerTypeRanking,
    AnswerTypeMatrix,
  },
  props: {
    type: {
      default: () => {},
    },
    data: {
      default: () => {},
    },
  },
  data() {
    return {
      exampleQuestion: [],
      candidatQuestion: [],
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions({
      previewSection: 'newSection/previewSection',
    }),

    async fetchData() {
      let exampleQuestion = []
      this.data.exampleQuestion.map(question => {
        exampleQuestion.push(question.id)
      })

      let data = {
        isUseExample: this.data.isUseExample,
        exampleQuestion: exampleQuestion,
        newCategory: this.data.category[0].id,
        isRandomTag: this.data.isRandomTag,
        levels: this.data.levels,
      }

      const response = await this.previewSection(data)

      this.exampleQuestion = response.data.introductionList

      //add total video play for example
      for (var i in this.exampleQuestion) {
        for (var j in this.exampleQuestion[i].files) {
          if (this.exampleQuestion[i].files[j].fileType == 'video') {
            this.exampleQuestion[i].files[j]['totalPlayVideo'] = 0
          }
        }
      }

      this.candidatQuestion = response.data.questionList

      //add total video play for question
      for (var k in this.candidatQuestion) {
        for (var l in this.candidatQuestion[k].files) {
          if (this.candidatQuestion[k].files[l].fileType == 'video') {
            this.candidatQuestion[k].files[l]['totalPlayVideo'] = 0
          }
        }
      }
    },

    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },

    // For play video
    playVideo(id, index, indexFile) {
      this.$refs['video_' + id + '_' + index + '_' + indexFile][0].player.play()
    },

    // For end video
    async endVideo(id, indexQuestionBefore, indexFile, identifier) {
      var indexQuestion = indexQuestionBefore - 1
      if (identifier == 'example') {
        this.exampleQuestion[indexQuestion].files[indexFile][
          'totalPlayVideo'
        ] += 1
      } else {
        this.candidatQuestion[indexQuestion].files[indexFile][
          'totalPlayVideo'
        ] += 1
      }
      this.$forceUpdate()
    },

    // For set options
    setOptions(src) {
      return {
        // videojs options
        muted: false,
        controls: false,
        width: 320,
        height: 240,
        language: 'en',
        sources: [
          {
            type: 'video/mp4',
            src: src,
          },
        ],
      }
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>

<style lang="scss">
video::-webkit-media-controls {
  display: none;
}

.wrapper {
  padding: 0 !important;
}

/* Could Use thise as well for Individual Controls */
video::-webkit-media-controls-play-button {
}

video::-webkit-media-controls-volume-slider {
}

video::-webkit-media-controls-mute-button {
}

video::-webkit-media-controls-timeline {
}

video::-webkit-media-controls-current-time-display {
}
</style>
